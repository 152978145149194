import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import CountDown from '@components/Shared/CountDown';
import TableCustom from './components/table';
import { TitlePage } from '@src/@styles/styles.global';

import Services, { IEnvios, ICampanha } from './service';

import { Form, Select as SelectAntd } from 'antd';

import { useAuth } from '@hooks/AuthenticateContext';

const columnsLabel = {
  estrategia_nome: 'Estratégia',
  tipo_acao_descricao: 'Tipo',
  estrategia_id: 'ID',
};

const ListEnvios: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IEnvios[]>([]);
  const [campanhaSelected, setCampanhaSelected] = useState(0);
  const [campanhas, setCampanhas] = useState<ICampanha[]>([] || null);

  const loadEnvios = async () => {
    setLoading(true);
    const services = new Services();

    const { error, response } = await services.loadEnvios();

    if (!error && response) {
      setData(response);
    }

    setLoading(false);
  };

  const handleImpressaoLocal = async (id: string) => {
    const services = new Services();

    const { response, error } = await services.impressaoLocal(id);

    if (!error && response?.boletoURL) {
      window.open(response?.boletoURL);
    } else {
      console.log(error);
    }
  };

  const handleEnvioEmail = async (id: string) => {
    const services = new Services();

    const { error } = await services.envioEmail(id);

    if (!error) {
      await loadEnvios();

      enqueueSnackbar('E-mails enviados com sucesso!', {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const getFileGrafica = async (id: string) => {
    const services = new Services();

    const { error, response } = await services.getArquivoGrafica(id);

    if (!error && response && response.length > 0) {
      response.map(url => {
        window.open(url.url);
      });
    }
  };

  async function loadCampanhas() {
    const services = new Services();

    const { error, response } = await services.getCampanhas();
    if (!error && response?.length) {
      setCampanhas(response);
      setCampanhaSelected(response[0].id);
      loadEnviosCampanha(
        response[0].id,
      );
    }
  }

  const loadEnviosCampanha= async (campanha_id: number) => {
    setLoading(true);
    const services = new Services();

    const { error, response } = await services.loadEnviosCampanha({ campanha_id: campanha_id, });

    if (!error && response) {
      setData(response);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadCampanhas();
    window.document.title = 'CRM - Lista de Envios';
  }, []);


  const { user, signOut } = useAuth();


    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);



  return (
    <Grid container>
      <Grid item md={4} xs={12}>
        <TitlePage style={{ margin: 5 }}>Envios</TitlePage>
      </Grid>

      <Grid item md={4} xs={12} style={{ margin: 5 }}>
        <Form.Item label={<label style={{ fontSize: 20 }}>Campanha</label>}>
          <SelectAntd
            style={{ width: '100%' }}
            placeholder="Campanha"
            onChange={(e: number) => {
              setCampanhaSelected(e);
              loadEnviosCampanha(e)
            }}
            value={campanhas[0] ? campanhaSelected : 0}
          >
            {campanhas.map((option, index) => (
              <SelectAntd.Option key={option.id} value={option.id}>
                {option.descricao}
              </SelectAntd.Option>
            ))}
          </SelectAntd>
        </Form.Item>
      </Grid>
      <Grid item lg={12} xs={12}>
        <CountDown
          minutesDefault={1}
          changeFunction={loadEnviosCampanha}
          title="Tempo para atualização dos dados"
        />
      </Grid>
      <Grid item lg={10} xs={12}>
        <TableCustom
          FieldsBold={['estrategia_id']}
          keys={['estrategia_id', 'estrategia_nome', 'tipo_acao_descricao']}
          data={data}
          columns={[columnsLabel]}
          loading={loading}
          handleImpressaoLocal={(id: string) => handleImpressaoLocal(id)}
          handleEnvioEmail={(id: string) => handleEnvioEmail(id)}
          handleDownloadArquivoGrafica={(id: string) => getFileGrafica(id)}
        />
      </Grid>
    </Grid>
  );
};

export default ListEnvios;
